import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { MD5 } from 'crypto-js';
import { environment } from '../environments/environment';
import { User } from '../user/user.types';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        credentials.password = MD5(credentials.password).toString();

        return this._httpClient.post(environment.baseUrl + '/auth/authentication', credentials, {withCredentials: true}).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.token;

                // Handle token decoding and user creation
                const user = this.createUserFromToken(this.accessToken);

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient
            .post(environment.baseUrl + '/auth/refresh-token', {
                token: this.accessToken,
            },
            {withCredentials: true})
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.token) {
                        this.accessToken = response.token;
                    }

                    // Handle token decoding and user creation
                    const user = this.createUserFromToken(this.accessToken);

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = user;

                    // Return true
                    return of(true);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        this._httpClient
            .post(environment.baseUrl + '/auth/sign-out', null)
            .subscribe();

        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post(environment.baseUrl + '/auth/authentication', credentials, {withCredentials: true});
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    // create user from decoded token
    private createUserFromToken(accessToken: string): any {
        const decodedToken = AuthUtils.getTokenData(accessToken);

        if (!decodedToken || !decodedToken.user_role || decodedToken.user_role !== 'admin') {
            throw new Error('Invalid user role');
        }

        let user: User = {
            id: decodedToken.user_id,
            role: decodedToken.user_role,
            type: decodedToken.user_type,
            email: decodedToken.user_email,
            status: 'online',
            avatar: environment.resourcesUrl + '/teachers/' + decodedToken.user_id + '.jpg'
        };

        // Fetch additional user profile data asynchronously if needed
        this._userService.get().subscribe((userProfile) => {
            user.firstName = userProfile.firstName;
            user.lastName = userProfile.lastName;
            user.phone = userProfile.phone;
            user.occupation = userProfile.occupation;
            user.createdOn = userProfile.createdOn;
            user.lastLoginOn = userProfile.lastLoginOn;
            user.lastPasswordResetOn = userProfile.lastPasswordResetOn;
            user.updatedOn = userProfile.updatedOn;
            user.verifiedBy = userProfile.verifiedBy;
            user.verifiedOn = userProfile.verifiedOn;
            user.cards = userProfile.cards;
            user.children = userProfile.children;
            user.notifications = userProfile.notifications;
            user.businessDetails = userProfile.businessDetails;
        });

        return user;
    }
}
